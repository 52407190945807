(function () {
	'use strict';
	angular
		.module('event')
		.controller('AttendeeCtrl', [
			'$sce',
			'$scope',
			'$location',
			'$timeout',
			'$q',
			'seedcodeCalendar',
			'calendarIO',
			'utilities',
			'dataStore',
			'csvData',
			'environment',
			'manageResources',
			'interventions',
			'eventSettings',
			EventCtrl,
		]);

	function EventCtrl(
		$sce,
		$scope,
		$location,
		$timeout,
		$q,
		seedcodeCalendar,
		calendarIO,
		utilities,
		dataStore,
		csvData,
		environment,
		manageResources,
		interventions,
		eventSettings
	) {
		if ($scope.edit && $scope.edit.attendees) {
			$scope.$evalAsync(function () {
				//$scope.attendees = $scope.edit.attendees;
				$scope.attendees = [];
				for (var i = 0; i < $scope.edit.attendees.length; i++) {
					if ($scope.edit.attendees[i].isInvitee) {
						$scope.attendees.push($scope.edit.attendees[i]);
					}
				}
				$scope.attendeesDelete = [];
				$scope.edit.editEvent.attendeesDelete = $scope.attendeesDelete;
				$scope.edit.editEvent.attendees = $scope.attendees;
				$scope.attendeesNew = [];
				$scope.edit.editEvent.attendeesNew = $scope.attendeesNew;
				$scope.makeInvitee = [];
				$scope.edit.editEvent.makeInvitee = $scope.makeInvitee;
			});
		}

		$scope.cancelAttendees = function () {
			$scope.edit.editEvent.attendeesSave = false;
			$scope.popover.config.show = false;
		};

		$scope.saveAttendees = function () {
			$scope.edit.editEvent.attendeesSave = true;
			$scope.popover.config.show = false;
		};

		$scope.removeAttendee = function (person) {
			//find element to remove
			var element;
			for (var i = 0; i < $scope.attendees.length; i++) {
				if ($scope.attendees[i].id === person.id) {
					element = i;
					break;
				}
			}
			//if this person had a recordId then they need to be deleted from the source
			if (person.recordId) {
				$scope.attendeesDelete.push(person);
			}
			$scope.$evalAsync(function () {
				var deleted = $scope.attendees.splice(element, 1);
			});
		};

		$scope.selectPerson = function (person) {
			person.status = 'New';
			$scope.peopleSearch = false;
			if (isParent(person)) {
				$scope.makeInvitee.push(person);
			} else {
				$scope.attendeesNew.push(person);
			}
			$scope.$evalAsync(function () {
				$scope.attendees.push(person);
			});
			function sortArray(a, b) {
				return a.name > b.name ? 1 : -1;
			}
			function isParent(person) {
				var existing = $scope.edit.attendees;
				for (var i = 0; i < existing.length; i++) {
					if (
						person.id === existing[i].id &&
						existing[i].isParent &&
						!existing[i].isInvitee
					) {
						person.recordId = existing[i].recordId;
						return true;
					}
				}
				return false;
			}
		};

		$scope.hideSearch = function () {
			$scope.peopleSearch = false;
		};

		$scope.findPeople = function (e, criteria) {
			if (!criteria) {
				return;
			}
			var exclude;
			var numberOfQueries = 2;
			if ($scope.edit.exclude) {
				if ($scope.edit.exclude === 'Contact') {
					exclude = 'Contact';
					numberOfQueries = numberOfQueries - 1;
				} else if ($scope.edit.exclude === 'User') {
					exclude = 'User';
					numberOfQueries = numberOfQueries - 1;
				}
			}
			var queriesReturned = 0;
			var results = [];
			if (
				e.keyCode === 13 ||
				e.keyCode === 9 ||
				e.keyCode === 10 ||
				!e.keyCode
			) {
				e.preventDefault();
				if (exclude !== 'User') {
					fbk.quickFind('User', 'Name', 'Name', criteria, callback);
				}
				if (exclude !== 'Contact') {
					fbk.quickFind(
						'Contact',
						'Name',
						'Name',
						criteria,
						callback
					);
				}
			}

			function callback(data) {
				queriesReturned++;
				results = data.concat(results);
				if (queriesReturned === numberOfQueries) {
					var result = [];
					for (var i = 0; i < results.length; i++) {
						var thisResult = {};
						thisResult.name = results[i].Name;
						thisResult.id = results[i].Id;
						thisResult.type = results[i].attributes.type;
						thisResult.display = $sce.trustAsHtml(
							'<span>' +
								thisResult.name +
								' </span><span style="font-style:italic;">(' +
								thisResult.type +
								')</span>'
						);
						if (!personIsAttendee(thisResult)) {
							result.push(thisResult);
						}
					}
					result.sort(sortArray);
					$scope.$evalAsync(function () {
						$scope.people = result;
						$scope.peopleSearch = true;
					});
				}
			}

			function sortArray(a, b) {
				return a.name > b.name ? 1 : -1;
			}

			function personIsAttendee(person) {
				var ownerId = $scope.edit.editEvent.resourceID
					? $scope.edit.editEvent.resourceID
					: '';
				var result = false;
				if ($scope.attendees.length > 0) {
					for (var i = 0; i < $scope.attendees.length; i++) {
						if (
							$scope.attendees[i].id === person.id ||
							ownerId === person.id
						) {
							return true;
						}
					}
				}

				return result;
			}
		};
	}
})();
